import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import { Row, Col } from "antd";
import axios from "utils/axios";
import { Navbar } from "Components/common";

const tailLayout = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 18, offset: 3 },
    md: { span: 18, offset: 3 },
  },
};

function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const onFinish = async (values) => {
    setLoading(true);
    try {
      await axios.post("/accounts/resetpassword", values);
      setSuccess(true);
    } catch (error) {
      message.error(
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.detail) ||
          "Something went wrong"
      );
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    message.error("Something went wrong.");
  };

  return (
    <React.Fragment>
      <Navbar />
      <Row>
        <Col
          xs={{ span: 16, offset: 4 }}
          sm={{ span: 12, offset: 6 }}
          md={{ span: 6, offset: 9 }}
        >
          <h4 className="form-header">Forgot your password?</h4>
          <Form
            name="resetpassword"
            layout="vertical"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please enter your email",
                },
                {
                  type: "email",
                  message: "Please enter a valid email address",
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <p style={{ fontSize: 12, color: "#666666" }}>
              Enter your registered email to receive a link to reset your
              password.
            </p>

            <Form.Item {...tailLayout}>
              <Button type="primary" block htmlType="submit" loading={loading}>
                Reset Password
              </Button>
            </Form.Item>
            {success && (
              <p
                style={{ fontSize: 12, color: "#666666", textAlign: "center" }}
              >
                Check your email for the password reset link!
              </p>
            )}
          </Form>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default ResetPassword;
