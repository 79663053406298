import { Button, message, List, Spin, Popconfirm, Avatar, Space } from "antd";
import React, { useEffect, useState } from "react";
import axios from "utils/axios";
import { useAuth } from "hooks/useAuth";
import { NavLink } from "react-router-dom";



const DeleteSlackButton = ({ item, fetchSlackData }) => {

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const onDeleteSlack = async () => {

        setLoading(true);
        try {
            await axios.get(`message/slack/uninstall/${item.id}`);
            fetchSlackData()
            message.success("Message destination deleted");
            setOpen(false)
        } catch (error) {
            message.error(
                (error &&
                    error.response &&
                    error.response.data &&
                    error.response.data.detail) ||
                "Something went wrong"
            );
            setOpen(false)
        } finally {
            setLoading(false);
        }
    };

    return (
        <Popconfirm
            title="Confirm"
            description="Are you sure you want to remove this integration?"
            okText="Yes, Remove"
            // open={open}
            onConfirm={onDeleteSlack}
            okButtonProps={{
                loading: loading,
            }}
            onCancel={() => setOpen(false)}
        >
            <a onClick={() => setOpen(true)}>
                X
            </a>
        </Popconfirm>
    );
}

const SlackIntegration = () => {

    const [slackAccount, setSlackAccount] = useState(null);

    const { currentUser, isSubscribed } = useAuth();

    const accountId = currentUser.id

    const redirectUri = `${process.env.REACT_APP_BASE_URL}/message/slack/callback`
    const slackAuthoriseUrl = `https://slack.com/oauth/v2/authorize?scope=chat:write,chat:write.public,channels:read&redirect_uri=${redirectUri}&client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}&state=${accountId}`

    const fetchSlackData = () => {
        axios
            .get("message/slack")
            .then((res) => setSlackAccount(res.data))
            .catch(error => console.log(error))
    }

    useEffect(() => {
        fetchSlackData();
    }, [])

    const handleClick = () => {
        if (slackAccount && !isSubscribed && slackAccount.length >= 1) {
            message.info("Free plan includes 1 message destination. Upgrade to a paid plan to connect more.");
        }
        else {
            window.location.href = slackAuthoriseUrl;
        }
    }

    return (
        <div class="message-destination">
            <p><b>Connected Message Destinations</b></p>

            {slackAccount ? (
                slackAccount.length > 0 ? (
                    <List className="mb-1"
                        itemLayout="horizontal"
                        dataSource={slackAccount}
                        renderItem={(item, index) => (
                            <List.Item
                                actions={[<DeleteSlackButton item={item} fetchSlackData={fetchSlackData} />]}
                            >
                                <List.Item.Meta
                                    title={item.name}
                                    avatar={<Avatar size="small" shape="square" src="/static/slack.png" />}
                                // description={"Created at : " + item.created}
                                />
                            </List.Item>
                        )}
                    />) : (
                    <p style={{ color: "#828282" }}>Please connect your first message destination.</p>
                )
            ) : (
                <p><Spin size="small" /></p>
            )}

            <Button className="button-with-image" onClick={handleClick}>
                {/* <Space align="center"> */}
                    <img class="button-image" alt="slack-icon" src="/static/slack.png"></img>
                    <span> Connect Slack </span>
                {/* </Space> */}
            </Button>

            {slackAccount && !isSubscribed && slackAccount.length >= 1 &&
                <p>
                    To connect more message destinations, <NavLink to="/billing"><u>upgrade to a paid plan</u></NavLink>.
                </p>
            }

        </div>)
}


export { SlackIntegration };