import React from "react";
import { Spin } from "antd";
import { Navigate } from "react-router-dom";
import { useAuth } from "hooks/useAuth";

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  const { isLoading, currentUser } = useAuth();
  if (isLoading) {
    return (
      <Spin style={{ position: 'absolute', top: '50%', left: '50%' }} />
    );
  }

  if (!restricted && currentUser) {
    return <Navigate to="/" />;
  }

  return <Component {...rest} />;
};

export default PublicRoute;
