import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

//import "antd/dist/antd.css";
import "./App.css";

import Dashboard from "pages/Dashboard";
import Login from "pages/Login";
import Register from "pages/Register";
import ForgotPassword from "pages/ForgotPassword";
import ResetPassword from "pages/ResetPassword";
import Error404 from "pages/Error404";
import { AuthProvider } from "./contexts/Auth";
import { PublicRoute, PrivateRoute } from "Components/routes";
import StatsBot from "pages/StatsBot";
import Integration from "pages/Integration";
import Billing from "pages/Billing";
import CreateStatsBot from "pages/CreateStatsBot";
import ModifyStatsBot from "pages/ModifyStatsBot";

const App = () => {
  return (
    <React.Fragment>
      <AuthProvider>
        <Router>
          <Routes>
            <Route exact path="/login" element={<PublicRoute component={Login} />} />
            <Route exact path="/register" element={<PublicRoute component={Register} />} />
            <Route exact path="/forgotpassword" element={<PublicRoute component={ForgotPassword} />} />
            <Route exact path="/forgotpassword/:id" element={<PublicRoute component={ForgotPassword} />} />
            <Route exact path="/resetpassword" element={<PublicRoute component={ResetPassword} />} />

            <Route exact path="/" element={<PrivateRoute component={Dashboard} />}>

              <Route path="/statsbot" element={<PrivateRoute component={StatsBot} />} />
              <Route path="/statsbot/:id" element={<PrivateRoute component={ModifyStatsBot} />} />
              <Route path="/statsbot/new" element={<PrivateRoute component={CreateStatsBot} />} />

              <Route path="/integration" element={<PrivateRoute component={Integration} />} />
              <Route path="/billing" element={<PrivateRoute component={Billing} />} />

            </Route>
            
            <Route path='*' element={<PublicRoute component={Error404} />} />
          </Routes>
        </Router>
      </AuthProvider>
    </React.Fragment>
  );
};

export default App;
