import { Divider, message} from "antd";
import React, { useEffect } from "react";
import { StripeIntegration } from "Components/Integration/dataSource/StripeIntegration";
import { SlackIntegration } from "Components/Integration/SlackIntegration";
import { DataSourceIntegration } from "Components/Integration/dataSource/DataSourceIntegration";


const Integration = () => {

    // Show messages if intergartion is successful.
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const status = searchParams.get('status');
        const res_message = searchParams.get('message');

        if (status && status == 'error') {
            message.error(res_message);
        }
        if (status && status == 'success') {
            message.success("Integrated successfully!")
        }
        if (status && status == 'warning-message') {
            message.warning("Message Destination is already connected.")
        }
        if (status && status == 'warning-source') {
            message.warning("Data Source is already connected.")
        }
    }, [])

    return (
        <div class='integration'>
            <h2 style={{marginBottom: "30px"}}> Integrations </h2>

            <SlackIntegration />
            <Divider />  
            <DataSourceIntegration />
            
        </div>
    )
}

export default Integration;