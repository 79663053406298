import React, { useEffect, useState } from "react";
import { Form, Input, Button, message, Spin } from "antd";
import { Row, Col } from "antd";
import axios from "utils/axios";
import { Navbar } from "Components/common";
import { useParams } from "react-router-dom";

const tailLayout = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 18, offset: 3 },
    md: { span: 18, offset: 3 },
  },
};

function ForgotPassword() {
  const params = useParams();
  const { id: uniqueId } = params;
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    fetchResetPasswordUser();
    //return () => {};
  }, []);

  const fetchResetPasswordUser = async () => {
    setLoading(true);
    try {
      var res = await axios.get(
        `/accounts/resetpassword/email?reset_password_token=${uniqueId}`
      );
      setUserEmail(res.data?.email);
    } catch (error) {
      message.error(
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.detail) ||
          "Something went wrong"
      );
    } finally {
      setLoading(false);
    }
  };

  const isValidForm = (values) => {
    const { new_password, confirm_new_password } = values;
    if (new_password === confirm_new_password) {
      return true;
    }
    return false;
  };

  const onFinish = async (values) => {
    if (isValidForm(values)) {
      const data = {
        ...values,
        reset_password_token: uniqueId,
      };
      setSubmitting(true);
      try {
        await axios.post("/accounts/resetpassword/confirm", data);
        setSuccess(true);
      } catch (error) {
        message.error(
          (error &&
            error.response &&
            error.response.data &&
            error.response.data.detail) ||
            "Something went wrong"
        );
      } finally {
        setSubmitting(false);
      }
    } else {
      message.error("Passwords must be same");
    }
  };

  const onFinishFailed = (errorInfo) => {
    message.error("Something went wrong.");
  };

  return (
    <React.Fragment>
      <Navbar />
      {loading ? (
        <Spin style={{ position: "absolute", top: "50%", left: "50%" }} />
      ) : (
        <Row>
          <Col
            xs={{ span: 16, offset: 4 }}
            sm={{ span: 12, offset: 6 }}
            md={{ span: 6, offset: 9 }}
          >
            <h4 className="form-header">Set a new password</h4>
            <Form
              name="forgotpassword"
              layout="vertical"
              initialValues={{
                ["email"]: userEmail,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="Email Address"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter your email",
                  },
                  {
                    type: "email",
                    message: "Please enter a valid email address",
                  },
                ]}
              >
                <Input disabled={true} placeholder="Email" />
              </Form.Item>

              <Form.Item
                label="New Password"
                name="new_password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your new password",
                  },
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>

              <Form.Item
                label="Confirm New Password"
                name="confirm_new_password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your confirm password",
                  },
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>

              <Form.Item {...tailLayout}>
                <Button
                  type="primary"
                  block
                  htmlType="submit"
                  loading={isSubmitting}
                >
                  Set new password
                </Button>
              </Form.Item>
              {success && (
                <p
                  style={{
                    fontSize: 12,
                    color: "#666666",
                    textAlign: "center",
                    whiteSpace: "pre",
                  }}
                >
                  <strong>Success!</strong> Your new password has been set.
                  {"\n"}
                  <a href="/login">Click here to login</a>
                </p>
              )}
            </Form>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
}

export default ForgotPassword;
