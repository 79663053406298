import React, { useEffect } from "react";
import { Layout } from "antd";
import { Navbar } from "Components/common";
import Dashboard from "Components/dashboard";
import axios from "utils/axios";
import { useNavigate } from "react-router-dom";

const HomePage = () => {

  const navigate = useNavigate()

  useEffect(() => {

    if (window.location.pathname === '/') {
      const fetchIntegrationData = async () => {
        try {
          // Fetch the number of Stripe accounts
          const stripeRes = await axios.get("source/stripe/");
          const stripeCount = stripeRes.data.length;

          // Fetch the number of Slack accounts
          const slackRes = await axios.get("message/slack");
          const slackCount = slackRes.data.length;

          // Check the conditions and navigate accordingly
          if (slackCount === 0 || stripeCount === 0) {
            navigate('/integration');
          } else {
            navigate('/statsbot');
          }
        } catch (error) {
          console.log(error);
        }
      };

      fetchIntegrationData();
    };

  }, []);


  return (
    <Layout className="layout">
      <Navbar />
      <Dashboard />
    </Layout>
  );
};

export default HomePage;
