import { Link } from "react-router-dom";
import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Space } from "antd";
import { setTokenToAxios } from "../../utils/axios";

import { useAuth } from "hooks/useAuth";


export default function Header() {

    const { currentUser, setCurrentUser } = useAuth();

    const logout = () => {
        localStorage.removeItem("RS_auth_token");
        setTokenToAxios("");
        setCurrentUser(null);
    };

    const emailMenu = (
        <Menu className="dropdown-menu">
            <Menu.Item>
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="mailto:hello@statsbot.xyz?subject=StatsBot%3A%20Contact%20Support&body=Hi%2C%20I%20need%20help%20with%20StatsBot."
                >
                    Contact support
                </a>
            </Menu.Item>
            <Menu.Item>
                <a href="https://statsbot.canny.io/feature-requests" target="_blank">
                    Request a feature
                </a>
            </Menu.Item>
            <Menu.Item
                danger
                onClick={logout}
                style={{ borderTop: "0.5px solid #eee" }}
            >
                Logout
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            <header className="header">
                <div className="header__container">
                    <div className="logo__container">
                        <Link to="/statsbot" className="logo">
                            <h3
                                style={{
                                    fontSize: "1.2rem",
                                    fontWeight: "600",
                                    display: "inline",
                                    color: "black",
                                }}
                            >
                                StatsBot
                            </h3>
                        </Link>

                    </div>

                    <nav style={{ display: "flex", alignItems: "center" }}>
                        {currentUser ? (
                            <>

                                {/* When user on free plan */}
                                {/* {currentUser?.subscription &&
                                    currentUser?.subscription?.is_active ? (
                                    <span style={{ color: "#8000F6", fontSize: 12 }}>
                                        Premium
                                    </span>
                                ) : (
                                    <Button onClick={redirectToSubscribe} className="upgrade-btn" id="12">
                                        Upgrade to Premium
                                    </Button>
                                )} */}

                                <Dropdown className="ml-1" overlay={emailMenu}>
                                    <a
                                        className="ant-dropdown-link menu-item"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        <Space>
                                            {currentUser.email}
                                            <DownOutlined />
                                        </Space>

                                        {/* <img className="icon" src="/static/icons/down.svg" /> */}
                                    </a>
                                </Dropdown>

                            </>
                        ) : (
                            <>
                                <Link to="/login" className="nav-link">
                                    <h3
                                        style={{
                                            fontSize: "14px",
                                            display: "inline",
                                        }}
                                    >
                                        Login
                                    </h3>
                                </Link>
                                <Link to="/register">
                                    <Button type="primary">Create account</Button>
                                </Link>
                            </>
                        )}
                    </nav>
                </div>

            </header>
        </>
    );
}