import React, { useState } from "react"
import { Button, Input, Modal, Space, message, Form } from "antd"
import axios from "utils/axios";

const ShopifyPartnerModal = ({ show, triggerModal, fetchDataSources }) => {

    const [form] = Form.useForm();

    const handleConnect = (data) => {
    
        axios
            .post("source/shopifypartner/connect", data)
            .then((res) => {
                if (res.status === 201) {
                    message.success('Integrated successfully!')
                    triggerModal(false)
                    fetchDataSources()
                }
            })
            .catch((error) => {
                if (error.response.data['status'] == 'warning')
                    message.warning(error.response.data['message'])
                else if (error.response.data['status'] == 'error')
                    message.error(error.response.data['message'])
                else message.error("Something went wrong.")
            })
            .finally(() => {
                form.resetFields()
            })
    };

    return (
        <Modal
            form={form}
            visible={show}
            title="Connect Shopify Partner"
            onCancel={() => triggerModal(false)}
            footer={null}
        >
            <p style={{
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '20px',
                letterSpacing: '0.001em',
                textAlign: 'left'
            }}>
                To connect your partner account, you need to create an API key in your
                Shopify Partner admin dashboard. Follow the steps in <a href="https://docs.google.com/document/d/1KOXXmxY_GhBWscsMQ7OzPsmZME7kcLlUs7hcd-cjcgE/edit?usp=sharing" target="_blank"><u>this help article</u></a>.
            </p>

            <Form
                onFinish={handleConnect}
                layout="vertical"
                scrollToFirstError={true}
            >
                <Form.Item
                    label="Name"
                    name="name"
                    // rules={[{ required: true, message: 'Please enter a name' }]}
                >
                    <Input placeholder="Name" />
                </Form.Item>

                <Form.Item
                    label="API Key"
                    name="partner_api_key"
                    rules={[{ required: true, message: 'Please enter an API key' }]}
                >
                    <Input placeholder="API key" />
                </Form.Item>

                <Form.Item
                    label="Organization ID"
                    name="organisation_id"
                    rules={[{ required: true, message: 'Please enter an organization ID' }]}
                >
                    <Input placeholder="Organization ID" />
                </Form.Item>

                <Form.Item
                    label="App ID"
                    name="app_id"
                    rules={[{ required: true, message: 'Please enter an App ID' }]}
                >
                    <Input placeholder="App ID" />
                </Form.Item>

                <Form.Item style={{ textAlign: 'right' }}>
                    <Button type="primary" htmlType="submit">
                        Connect
                    </Button>
                </Form.Item>
            </Form>

        </Modal>
    );
}

const ShopifyPartnerIntegration = ({fetchDataSources, isIntegrationValid}) => {

    const [modalVisible, setModalVisible] = useState(false);

    const toggleModal = (val = true) => {
        if (isIntegrationValid()) {
            setModalVisible(val);
        }
    };

    return (<div>
        <Button className="button-with-image" onClick={toggleModal}>
            <img class="button-image" alt="stripe-icon" src="/static/shopify.png"></img>
            <span> Connect Shopify Partner </span>
        </Button>

        <ShopifyPartnerModal show={modalVisible} triggerModal={toggleModal} fetchDataSources={fetchDataSources} />
    </div>)
}

export { ShopifyPartnerIntegration };