import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
// axios.defaults.baseURL = 'http://localhost:8000'



/* axios.defaults.headers.Authorization = localStorage.getItem("RS_auth_token")
  ? `Token ${localStorage.getItem("RS_auth_token")}`
  : ""; */

export const setTokenToAxios = (token) => {
  const newToken = token || localStorage.getItem("RS_auth_token") || "";

  if (!newToken) {
    axios.defaults.headers.Authorization = "";
    return;
  }

  axios.defaults.headers.Authorization = `Token ${newToken}`;
};

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("RS_auth_token");
    if (token) {
      config.headers["Authorization"] = "Token " + token;
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("RS_auth_token");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export default axios;
