import { StatsBotForm } from "Components/statsBotForm/StatsBotForm";
import React from "react";
import axios from "utils/axios";
import { message } from "antd";
import { useNavigate } from 'react-router-dom';


const CreateStatsBot = () => {

    const navigate = useNavigate()

    const handleCreate = async (values) => {
        // Convert post_time to a datetime object
        // values.post_time = values.post_time.format('HH:mm:ss');

        try {
            var res = await axios.post('bot/', values);
            message.success("StatsBot created successfully!")
            navigate('/statsbot')
            return res;
        } catch (error) {
            message.error(
                (error &&
                    error.response &&
                    error.response.data &&
                    error.response.data.detail) ||
                "Something went wrong"
            );
        }
    };

    return (
        <div>
            <h2>Create Stats Bot</h2>
            <StatsBotForm onSubmit={handleCreate} />
        </div>
    )
}

export default CreateStatsBot;