import React, { useState, useEffect } from "react";
import { Form, Input, Select, Button, Spin, message } from 'antd';
import axios from "utils/axios";
import { timezones } from "utils"
import { usePrompt } from "hooks/usePrompt";
import { defaultStripeMessage, defaultShopifyPartnerMessage } from "utils";
import { StripeMetricGrid, ShopifyPartnerMetricGrid } from "./metricGrid";

const { Option } = Select;


const StatsBotForm = ({ isEdit = false, initialValues = {}, onSubmit }) => {

    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);

    const [slackAccounts, setSlackAccounts] = useState([]);
    const [stripeAccounts, setStripeAccounts] = useState([]);
    const [slackChannels, setSlackChannels] = useState([]);
    const [defaultSlackAccount, setDefaultSlackAccount] = useState(null);
    const [defaultStripeAccount, setDefaultStripeAccount] = useState(null);
    const [dataSources, setDataSources] = useState(null);
    const [defaultDataSource, setDeafultDataSource] = useState(null);

    const [defaultMessage, setDefaultMessage] = useState(null)

    // store the current value of datasource (stripe or shopifypartner)
    const [currentDataSourceCategory, setCurrentDataSourceCategory] = useState(null)

    // To fetch the slack channels when the initialvalues are loaded the first time.
    const [initialChannelsLoaded, setInititalChannelsLoaded] = useState(false);

    const [showPostDay, setShowPostDay] = useState(false);
    const [showPostDate, setShowPostDate] = useState(false);

    const [showDialog, setShowDialog] = useState(false);

    usePrompt(
        `Configuration of this Stats Bot has not been saved. If you leave the page, this configuration will be lost. \n
Are you sure you want to leave this page?`,
        showDialog);

    const handleSubmit = (values) => {
        setIsLoading(true);
        setShowDialog(false);
        onSubmit(values)
            .catch(error => console.log(error))
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        // Fetch message destinations
        axios
            .get('message/slack')
            .then(res => {
                setSlackAccounts(res.data);
                setDefaultSlackAccount(res.data[0]);
            })
            .catch(error => console.error(error));

        // Fetch data sources
        axios
            .get("source/")
            .then((res) => {
                setDataSources(res.data);
                setDeafultDataSource(res.data[0]);
                
                // set the default message for stripe and slack accordingly when the page loads
                setDefaultMessageFunction(res.data[0].gateway_name)
                setCurrentDataSourceCategory(res.data[0].gateway_name)  
            })
            .catch(error => console.log(error))

    }, []);

    // Fetch all channels for the initial slack account.
    useEffect(() => {

        // set the metric grid according to the initial data_source
        if (isEdit && initialValues && initialValues.data_source) {
            setCurrentDataSourceCategory(initialValues.data_source.gateway_name)
        }

        if (initialValues && initialValues?.message_destination && !initialChannelsLoaded)
            axios
                .get(`message/slack/channels/${initialValues.message_destination.id}`)
                .then(res => {
                    setSlackChannels(res.data.channels)
                    setInititalChannelsLoaded(true)
                })
                .catch(error => console.error(error));

        if (initialValues && initialValues?.post_frequency) {
            if (initialValues?.post_frequency === 'Weekly') {
                setShowPostDay(true);
                setShowPostDate(false);
            } else if (initialValues?.post_frequency === 'Monthly') {
                setShowPostDay(false);
                setShowPostDate(true);
            }
        }
    }, [initialValues])

    const handleDestinationChange = (value) => {
        // Fetch message channel ID and set channel id to NULL
        form.setFieldsValue({ message_channel_id: null })
        setSlackChannels([]);
        axios
            .get(`message/slack/channels/${value}`)
            .then(res => {
                setSlackChannels(res.data.channels)
            })
            .catch(error => console.error(error));
    };

    const sendTestMessageToSlack = () => {
        const stats_message = form.getFieldValue('message');
        const message_destination_id = form.getFieldValue('message_destination')
        const message_channel_id = form.getFieldValue('message_channel_id')
        const data_source_id = form.getFieldValue('data_source')

        if (!message_channel_id || !data_source_id || !message_destination_id || !stats_message) {
            message.error('Fill Required values.');
            return;
        }

        const data = {
            message: stats_message,
            message_destination_id: message_destination_id,
            message_channel_id: message_channel_id,
            data_source_id: data_source_id
        }

        message.info('Message may take a few minutes if there is a lot of data to process.')
        axios
            .post(`bot/sentmessage/slack`, data)
            .then(() => {
                message.success('Message sent successfully!');
            })
            .catch(error => {
                message.error("Something went wrong.")
                console.error(error)
            }
            );

    }


    // Create a set of initial values if creating statsBot
    if (!isEdit) {
        initialValues = {
            'name': '',
            'message_destination': defaultSlackAccount,
            'data_source': defaultDataSource,
            'post_time': '12:30:00',
            'post_frequency': 'Daily',
            'timezone': 'UTC',
        }
    }

    const daysArray = Array.from({ length: 28 }, (_, index) => index + 1);

    // handel post frequency change
    const handlePostFrequencyChange = (value) => {

        if (value === 'Weekly') {
            setShowPostDay(true);
            setShowPostDate(false);
        } else if (value === 'Monthly') {
            setShowPostDay(false);
            setShowPostDate(true);
        } else {
            setShowPostDay(false);
            setShowPostDate(false);
        }
    }

    const getGatewayName = (name) => {
        if (name === 'stripe')
            return "Stripe"
        else if (name == "shopify_partner")
            return "Shopify Partner"
    }

    const setDefaultMessageFunction = (name) => {
        // if default datasource is stripe then use stripe defualt message else use shopify parnter default message
        if (name == 'stripe') {
            setDefaultMessage(defaultStripeMessage)
        }
        else if (name == 'shopify_partner') {
            setDefaultMessage(defaultShopifyPartnerMessage)
        }
    }

    const ondataSourceChange = (value) => {
        // update the metrics according to the current dataSourceType
        axios
            .get(`source/${value}`)
            .then((res) => {
                
                // When the datasource is changed change the default message accordingly.
                if (res.data.gateway_name == 'shopify_partner') {
                    form.setFieldsValue({message : defaultShopifyPartnerMessage})
                }
                else if (res.data.gateway_name == 'stripe') {
                    form.setFieldsValue({message : defaultStripeMessage})
                }
                
                setCurrentDataSourceCategory(res.data.gateway_name)
            })
            .catch(error => {
                console.error(error)
            });
    }

    return (
        <div className="statsbot-form">
            {/* wait for the values to load */}
            {(initialValues && initialValues.message_destination && initialValues.data_source) ?
                (<Form form={form}
                    onFinish={handleSubmit}
                    layout="vertical"
                    scrollToFirstError={true}
                    onFieldsChange={() => setShowDialog(true)}
                >
                    <div style={{ maxWidth: '70%', minWidth: '170px' }}>

                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[{ required: true, message: 'Please enter a name' }]}
                            initialValue={initialValues?.name}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Message Destination"
                            name="message_destination"
                            rules={[{ required: true, message: 'Please select a message destination' }]}
                            initialValue={initialValues?.message_destination?.id}
                        >
                            <Select defaultValue={initialValues?.message_destination?.name} showSearch onChange={handleDestinationChange}>
                                {slackAccounts ?
                                    slackAccounts.map((destination) => (
                                        <Option key={destination.id} value={destination.id}>
                                            {destination.name}
                                        </Option>
                                    )) : (
                                        <>No Items</>
                                    )
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Slack Channel"
                            name="message_channel_id"
                            rules={[{ required: true, message: 'Please enter a message channel' }]}
                            initialValue={initialValues?.message_channel_id}
                        >
                            <Select showSearch>
                                {slackChannels ?
                                    slackChannels.map((channel) => (
                                        <Option key={channel.name} value={channel.name}>
                                            #{channel.name}
                                        </Option>
                                    )) : (
                                        <>No Items</>
                                    )
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Data Source"
                            name="data_source"
                            rules={[{ required: true, message: 'Please select a data source' }]}
                            initialValue={initialValues?.data_source?.id}
                        >
                            <Select showSearch defaultValue={initialValues?.data_source?.name} onChange={ondataSourceChange}>
                                {dataSources ? (
                                    dataSources.map((dataSource) => (
                                        <Option key={dataSource.id} value={dataSource.id}>
                                            {dataSource.name} ({getGatewayName(dataSource.gateway_name)})
                                        </Option>
                                    ))) : (
                                    <>No Items</>
                                )}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Post Frequency"
                            name="post_frequency"
                            rules={[{ required: true, message: 'Please select a post frequency' }]}
                            initialValue={initialValues?.post_frequency}
                        >
                            <Select onChange={handlePostFrequencyChange} showSearch>
                                <Option value="Daily">Daily</Option>
                                <Option value="Daily, Weekdays Only">Daily, Weekdays Only</Option>
                                <Option value="Weekly">Weekly</Option>
                                <Option value="Monthly">Monthly</Option>
                            </Select>
                        </Form.Item>

                        {showPostDay && (
                            <Form.Item
                                label="Post Day"
                                name="post_day"
                                rules={[{ required: true, message: 'Please select a post day' }]}
                                initialValue={initialValues?.post_day ? initialValues?.post_day : 'Monday'}
                            >
                                <Select showSearch>
                                    <Option value="Sunday">Sunday</Option>
                                    <Option value="Monday">Monday</Option>
                                    <Option value="Tuesday">Tuesday</Option>
                                    <Option value="Wednesday">Wednesday</Option>
                                    <Option value="Thursday">Thursday</Option>
                                    <Option value="Friday">Friday</Option>
                                    <Option value="Saturday">Saturday</Option>
                                </Select>
                            </Form.Item>
                        )}

                        {showPostDate && (
                            <Form.Item
                                label="Post Date"
                                name="post_date"
                                rules={[{ required: true, message: 'Please select a post date' }]}
                                initialValue={initialValues?.post_date ? initialValues?.post_date : 1}
                            >
                                <Select showSearch defaultValue={initialValues?.post_date ? `Day ${initialValues?.post_date}` : 'Day 1'}>
                                    {daysArray.map((day) => (
                                        <Option key={day} value={day}>
                                            Day {day}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )}

                        <Form.Item
                            label="Post Time"
                            name="post_time"
                            rules={[{ required: true, message: 'Please select a time to send message' }]}
                            initialValue={initialValues?.post_time}
                        >
                            <Input type="time" />
                        </Form.Item>

                        <Form.Item
                            label="Timezone"
                            name="timezone"
                            rules={[{ required: true, message: 'Please select a timezone' }]}
                            initialValue={initialValues?.timezone}
                        >
                            <Select showSearch>
                                {timezones ? (
                                    timezones.map((timezone) => (
                                        <Option key={timezone} value={timezone}>
                                            {timezone}
                                        </Option>
                                    ))) : (
                                    <>No Items</>
                                )}
                            </Select>
                        </Form.Item>
                    </div>

                    <div style={{ position: "relative", marginTop: 50 }}>

                        <Form.Item
                            label="Message"
                            name="message"
                            rules={[{ required: true, message: 'Please enter a message' }]}
                            initialValue={isEdit ? initialValues?.message : defaultMessage}
                        >
                            <Input.TextArea autoSize={{
                                minRows: 10
                            }} />
                        </Form.Item>

                        <Button className="statbot-button" style={{ position: "absolute", top: -9, right: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={sendTestMessageToSlack}>
                            {/* <Space align="center"> */}
                            <img class="button-image" alt="slack-icon" src="/static/slack.png"></img>
                            <span>Send Test Message</span>
                            {/* </Space> */}
                        </Button>
                    </div>


                    <div class='variables'>
                        <b>Metrics variables:</b> (click to copy to clipboard)
                        
                        { currentDataSourceCategory === 'stripe' && < StripeMetricGrid />}
                        { currentDataSourceCategory === 'shopify_partner' && <ShopifyPartnerMetricGrid />}

                    </div>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            {isEdit ? "Update" : "Create"}
                        </Button>
                    </Form.Item>
                </Form>)
                : (
                    <Spin />
                )}
        </div>
    )
}

export { StatsBotForm };