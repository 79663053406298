import { Button } from "antd";
import React from "react";
import { useAuth } from "hooks/useAuth";



const StripeIntegration = ({fetchDataSources, isIntegrationValid}) => {

    const redirectUri = `${process.env.REACT_APP_BASE_URL}/source/stripe/callback`

    const { currentUser, isSubscribed } = useAuth();

    const accountId = currentUser.id
    const stripeAuthoriseUrl = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_STRIPE_CLIENT_ID}&scope=read_write&redirect_uri=${redirectUri}&state=${accountId}`

    const handleClick = () => {
        if (isIntegrationValid()) {
            window.location.href = stripeAuthoriseUrl;
        }
    }

    return (
        <Button className="button-with-image" onClick={handleClick}>
            {/* <Space align="center"> */}
                <img class="button-image" alt="stripe-icon" src="/static/stripe.png"></img>
                <span> Connect Stripe </span>
            {/* </Space> */}
        </Button>
        )
}


export { StripeIntegration };