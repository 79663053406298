import React, { useEffect, useState } from "react";
import { Button, message, Spin, Popconfirm, Alert } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { getStripe } from "utils";
import { useAuth } from "hooks/useAuth";
import axios from "utils/axios";

const Billing = () => {
    const [loading, setLoading] = useState(false);
    const [isSubscribing, setSubscribing] = useState(false);
    const [isCancelling, setCancelling] = useState(false);
    const [plans, setPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [subscribedPlan, setSubscribedPlan] = useState(null);
    const { currentUser } = useAuth();

    useEffect(() => {
        fetchPlans();
        //return () => {};
    }, []);

    const fetchPlans = async () => {
        setLoading(true);
        try {
            var res = await axios.get("/billing/plans");
            setPlans(res.data);
            getSubscribedPlan(res.data);
        } catch (error) {
            message.error(
                (error &&
                    error.response &&
                    error.response.data &&
                    error.response.data.detail) ||
                "Something went wrong"
            );
        } finally {
            setLoading(false);
        }
    };

    const getSubscribedPlan = (plans) => {
        if (currentUser?.subscription && currentUser?.subscription?.is_active) {
            const subscribedPlan = plans.find(
                (plan) => plan.id === currentUser.subscription.plan
            );
            setSubscribedPlan(subscribedPlan);
            setSelectedPlan(subscribedPlan);
        } else {
            const plan = plans.find((plan) => plan.id === 2);
            setSelectedPlan(plan);
        }
    };

    const getProductSuffix = (product) => {
        const { interval } = product;
        if (interval === "monthly") {
            return "/ month";
        } else if (interval === "yearly") {
            return "/ year";
        }
        return "";
    };

    const getProductDiscount = (product) => {
        const { interval } = product;
        if (interval === "yearly") {
            return "Save 50%";
        }
        return "";
    };

    const onUpdateSubscription = async (planId) => {
        setSubscribing(true);
        const data = {
            new_plan_id: planId,
        };
        try {
            await axios.post("/billing/stripe/modify/plan", data);
            message.success("Plan subscribed successfully!");
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        } catch (error) {
            message.error(
                (error &&
                    error.response &&
                    error.response.data &&
                    error.response.data.detail) ||
                "Something went wrong"
            );
        } finally {
            setSubscribing(false);
        }
    };

    const onPurchaseSubscription = async () => {
        try {
            const stripe = await getStripe();
            const { stripe_price_id } = selectedPlan;
            const items = [{ price: stripe_price_id, quantity: 1 }];
            stripe.redirectToCheckout({
                mode: "subscription",
                lineItems: items,
                successUrl: `${process.env.REACT_APP_BASE_URL}`, //?payment=success&session_id={CHECKOUT_SESSION_ID}
                cancelUrl: `${process.env.REACT_APP_BASE_URL}`,
                clientReferenceId: currentUser.id,
                customerEmail: currentUser.email,
            });
        } catch (error) {
            console.log(error);
            message.error("Something went wrong!");
        }
    };

    const onHandleSubscription = () => {
        if (subscribedPlan) {
            const { id } = selectedPlan;
            if (id === subscribedPlan?.id) {
                message.error("Selected plan is already subscribed!");
                return;
            }
            onUpdateSubscription(id);
        } else {
            onPurchaseSubscription();
        }
    };

    const onCancelSubscription = async () => {
        setCancelling(true);
        const data = {
            cancel_plan: true,
        };
        try {
            await axios.post("/billing/stripe/modify/plan", data);
            message.success("Subscription cancelled successfully!");
            setTimeout(() => {
                localStorage.setItem("DARK_MODE", JSON.stringify(false));
                window.location.reload();
            }, 1000);
        } catch (error) {
            message.error(
                (error &&
                    error.response &&
                    error.response.data &&
                    error.response.data.detail) ||
                "Something went wrong"
            );
        } finally {
            setCancelling(false);
        }
    };

    return (
        <div>
            <h2>Billing</h2>
            <div className="billing-div" style={{ display: "flex", flexDirection: "row" }}>
                <div className="billing-div-1"
                    style={{
                        flex: 1.2,
                        borderRight: "1px solid #eee",
                        paddingRight: "10px",
                    }}
                >
                    <h3
                        style={{
                            fontWeight: "700",
                            fontSize: 18,
                            whiteSpace: "pre",
                            lineHeight: 1.2,
                        }}
                    >
                        Track All Your
                        <span style={{ color: "#8000F6" }}> Revenue Stats</span> with {'\n'}
                        Ease 💰
                    </h3>
                    <p>
                        StatsBot is an indie project built by small & passionate team.
                        Thank you for using our app ❤️
                    </p>
                    <p>
                        Please support us by <strong>subscribing to a paid plan</strong>.
                        We sincerely appreciate your patronage.
                    </p>
                    <h4
                        style={{
                            fontWeight: "700",
                            fontSize: 14,
                            marginTop: 10,
                            whiteSpace: "pre",
                            lineHeight: 1.2,
                        }}
                    >
                        Premium features:
                    </h4>
                    <ul style={{ listStyle: "none", padding: 0 }}>
                        <li style={{ lineHeight: 2 }}>🤖 Create up to 10 Stats Bots (50 in Enterprise)</li>
                        <li style={{ lineHeight: 2 }}>💻 Unlimited Message Destinations</li>
                        <li style={{ lineHeight: 2 }}>🏦 Unlimited Data Sources</li>
                        {/* <li style={{ lineHeight: 2 }}>🔢 Unlock Advanced Revenue Metrics</li> */}
                        <li style={{ lineHeight: 2 }}>✉️ Priority support</li>
                        <li style={{ lineHeight: 2 }}>😃 Our immense gratitude</li>
                    </ul>

                    <p style={{
                        fontFamily: 'Roboto',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: 12,
                        lineHeight: 1.2,
                        color: "#828282"
                    }}>
                        <strong>Q. Is there a Free plan? </strong> <br></br>
                        A. Yes. You are automatically on the free plan when you signup.
                        The free plan only includes 1 bot, 1 message destination and 1 data source integration.
                    </p>
                </div>
                <div className="billing-div-2"
                    style={{
                        flex: 1,
                        marginLeft: "20px",
                        marginRight: "20px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between"
                    }}
                >
                    {loading ? (
                        <Spin style={{ display: "block" }} />
                    ) : (
                        <React.Fragment>
                            <div>
                                {currentUser?.subscription &&
                                    currentUser?.subscription?.is_active &&
                                    subscribedPlan && (
                                        <Alert message={
                                            <span>
                                                You are currently subscribed to the{" "}
                                                <strong>{subscribedPlan?.name}</strong> plan.
                                            </span>
                                        } type="info" />
                                    )}
                                <h4
                                    style={{
                                        fontWeight: "700",
                                        fontSize: 14,
                                        margin: "10px 0",
                                    }}
                                >
                                    {currentUser?.subscription &&
                                        currentUser?.subscription?.is_active
                                        ? "Update your plan: "
                                        : "Select a Plan: "}
                                </h4>
                                {plans.map((product, index) => {
                                    const isSelected = selectedPlan?.id === product.id || false;
                                    return (
                                        <div
                                            onClick={() => setSelectedPlan(product)}
                                            key={index}
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                padding: "10px",
                                                backgroundColor: "#F2F2F2",
                                                borderRadius: 5,
                                                margin: "10px 0",
                                                cursor: "pointer",
                                                border: isSelected && "2px solid #931EFF",
                                                position: "relative",
                                            }}
                                        >
                                            {isSelected && (
                                                <img
                                                    src="/static/plan_check.png"
                                                    style={{
                                                        width: 15,
                                                        height: 15,
                                                        position: "absolute",
                                                        right: -8,
                                                        top: -8,
                                                    }}
                                                />
                                            )}
                                            <div>
                                                <h4
                                                    style={{
                                                        fontWeight: "600",
                                                        fontSize: 14,
                                                        marginTop: "5px",
                                                    }}
                                                >
                                                    {product.name}
                                                </h4>
                                                <p style={{ margin: 0, fontSize: 12, color: "#4F4F4F" }}>
                                                    {product.description}
                                                    {/* <span style={{ fontWeight: "600" }}>
                                                        {" "}
                                                        {getProductDiscount(product)}
                                                    </span> */}
                                                </p>
                                            </div>
                                            <h4 style={{ fontSize: 14, fontWeight: "600", marginTop: "5px" }}>
                                                ${product.price}{" "}
                                                <span style={{ fontWeight: "500" }}>
                                                    {getProductSuffix(product)}
                                                </span>
                                            </h4>
                                        </div>
                                    );
                                })}
                            </div>
                            <div
                                style={{
                                    width: "100%",
                                }}
                            >
                                {selectedPlan && (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <h4 style={{ fontSize: 14 }}>Selected Plan</h4>
                                        <h4 style={{ fontSize: 14, fontWeight: "600" }}>
                                            {selectedPlan.name}
                                        </h4>
                                    </div>
                                )}
                                <Button
                                    loading={isSubscribing}
                                    disabled={selectedPlan && subscribedPlan && selectedPlan?.name === subscribedPlan?.name}
                                    style={{
                                        backgroundColor: (selectedPlan && subscribedPlan && selectedPlan?.name === subscribedPlan?.name) ? "#CCCCCC" : "#8000F6",
                                        color: "#FFFFFF",
                                        height: 38,
                                    }}
                                    onClick={onHandleSubscription}
                                    block={true}
                                >
                                    Subscribe Now
                                </Button>
                                {currentUser?.subscription &&
                                    currentUser?.subscription?.is_active && (
                                        <div style={{ textAlign: "center", cursor: "pointer" }}>
                                            <div style={{ color: "#666666", margin: "5px" }}>or</div>
                                            {isCancelling ? (
                                                <Spin style={{ display: "block" }} />
                                            ) : (
                                                <Popconfirm
                                                    placement="bottom"
                                                    icon={<ExclamationCircleFilled />}
                                                    title={
                                                        "Are you sure you want to cancel your paid plan?"
                                                    }
                                                    onConfirm={onCancelSubscription}
                                                    okText="Yes, cancel"
                                                    cancelText="Discard"
                                                >
                                                    <div style={{ color: "#EB5757", fontSize: 12 }}>
                                                        Cancel your plan
                                                    </div>
                                                </Popconfirm>
                                            )}
                                        </div>
                                    )}
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Billing;