import React from "react";
import { message } from "antd";

export const StripeMetricGrid = () => {

    const copyToClipboard = (event) => {
        const content = event.target.innerText;
        navigator.clipboard.writeText(content);
        message.info("Copied to clipboard.", 1)
    }

    return ( <>
    
    <p>The list below is of commonly used variables. See a full list of available variables
    along with their definitions <a href="https://docs.google.com/spreadsheets/d/131yUdea4eH-6cuLtgmh4Vy1VSEMx3eGuW7Zfwd1wV_Y/edit?usp=sharing" target="_blank"><u> in this help article.</u></a></p>

    <div class='grid-container'>
        <ul class='list1'>
            <li onClick={copyToClipboard}>{'{date}'}</li>
            <li onClick={copyToClipboard}>{'{activesubscriptions}'}</li>
            <li onClick={copyToClipboard}>{'{activemonthlysubscriptions}'}</li>
            <li onClick={copyToClipboard}>{'{activeyearlysubscriptions}'}</li>
            <li onClick={copyToClipboard}>{'{mrr}'}</li>
            <li onClick={copyToClipboard}>{'{arr}'}</li>
            <li onClick={copyToClipboard}>{'{refundscount}'}</li>
            <li onClick={copyToClipboard}>{'{refundsvalue}'}</li>
            <li onClick={copyToClipboard}>{'{failedpaymentscount}'}</li>
            <li onClick={copyToClipboard}>{'{failedpaymentsvalue}'}</li>
        </ul>

        <ul class='list2'>
            <li onClick={copyToClipboard}>{'{revenue}'}</li>
            <li onClick={copyToClipboard}>{'{revenue365d}'}</li>
            <li onClick={copyToClipboard}>{'{revenue365d+previousperiod+change%}'}</li>
            <li onClick={copyToClipboard}>{'{revenue28d}'}</li>
            <li onClick={copyToClipboard}>{'{revenue28d+previousperiod+change%}'}</li>
            <li onClick={copyToClipboard}>{'{revenue7d}'}</li>
            <li onClick={copyToClipboard}>{'{revenue7d+previousperiod+change%}'}</li>
            <li onClick={copyToClipboard}>{'{revenue1d}'}</li>
            <li onClick={copyToClipboard}>{'{revenue1d+previousperiod+change%}'}</li>
        </ul>

        <ul class='list3'>
            <li onClick={copyToClipboard}>{'{arpu}'}</li>
            <li onClick={copyToClipboard}>{'{arpu365d}'}</li>
            <li onClick={copyToClipboard}>{'{arpu365d+previousperiod+change%}'}</li>
            <li onClick={copyToClipboard}>{'{arpu28d}'}</li>
            <li onClick={copyToClipboard}>{'{arpu28d+previousperiod+change%}'}</li>
            <li onClick={copyToClipboard}>{'{arpu7d}'}</li>
            <li onClick={copyToClipboard}>{'{arpu7d+previousperiod+change%}'}</li>
            <li onClick={copyToClipboard}>{'{arpu1d}'}</li>
            <li onClick={copyToClipboard}>{'{arpu1d+previousperiod+change%}'}</li>
        </ul>

        <ul class='list4'>
            <li onClick={copyToClipboard}>{'{paymentscount}'}</li>
            <li onClick={copyToClipboard}>{'{paymentscount365d}'}</li>
            <li onClick={copyToClipboard}>{'{paymentscount365d+previousperiod+change%}'}</li>
            <li onClick={copyToClipboard}>{'{paymentscount28d}'}</li>
            <li onClick={copyToClipboard}>{'{paymentscount28d+previousperiod+change%}'}</li>
            <li onClick={copyToClipboard}>{'{paymentscount7d}'}</li>
            <li onClick={copyToClipboard}>{'{paymentscount7d+previousperiod+change%}'}</li>
            <li onClick={copyToClipboard}>{'{paymentscount1d}'}</li>
            <li onClick={copyToClipboard}>{'{paymentscount1d+previousperiod+change%}'}</li>
        </ul>
    </div>
    </>)
}


// Shopify Partner mertic grid

export const ShopifyPartnerMetricGrid = () => {

    const copyToClipboard = (event) => {
        const content = event.target.innerText;
        navigator.clipboard.writeText(content);
        message.info("Copied to clipboard.", 1)
    }

    return ( <>
    
    <p>The list below is of commonly used variables. See a full list of available variables
    along with their definitions <a href="https://docs.google.com/spreadsheets/d/131yUdea4eH-6cuLtgmh4Vy1VSEMx3eGuW7Zfwd1wV_Y/edit#gid=552193353?usp=sharing" target="_blank"><u> in this help article.</u></a></p>
    <div class='grid-container'>
        <ul class='list1'>
            <li onClick={copyToClipboard}>{'{date}'}</li>
            <li onClick={copyToClipboard}>{'{appname}'}</li>
            <li onClick={copyToClipboard}>{'{appnetrevenue365d}'}</li>
            <li onClick={copyToClipboard}>{'{appnetrevenue365d+previousperiod+change%}'}</li>
            <li onClick={copyToClipboard}>{'{appnetrevenue28d}'}</li>
            <li onClick={copyToClipboard}>{'{appnetrevenue28d+previousperiod+change%}'}</li>
            <li onClick={copyToClipboard}>{'{appnetrevenue7d}'}</li>
            <li onClick={copyToClipboard}>{'{appnetrevenue7d+previousperiod+change%}'}</li>
            <li onClick={copyToClipboard}>{'{appnetrevenue1d}'}</li>
            <li onClick={copyToClipboard}>{'{appnetrevenue1d+previousperiod+change%}'}</li>
        </ul>

        <ul class='list2'>
            <li onClick={copyToClipboard}>{'{appsubscriptionrevenue365d}'}</li>
            <li onClick={copyToClipboard}>{'{appsubscriptionrevenue365d+previousperiod+change%}'}</li>
            <li onClick={copyToClipboard}>{'{appsubscriptionrevenue28d}'}</li>
            <li onClick={copyToClipboard}>{'{appsubscriptionrevenue28d+previousperiod+change%}'}</li>
            <li onClick={copyToClipboard}>{'{appsubscriptionrevenue7d}'}</li>
            <li onClick={copyToClipboard}>{'{appsubscriptionrevenue7d+previousperiod+change%}'}</li>
            <li onClick={copyToClipboard}>{'{appsubscriptionrevenue1d}'}</li>
            <li onClick={copyToClipboard}>{'{appsubscriptionrevenue1d+previousperiod+change%}'}</li>
        </ul>

        <ul class='list3'>
            <li onClick={copyToClipboard}>{'{appusagerevenue365d}'}</li>
            <li onClick={copyToClipboard}>{'{appusagerevenue365d+previousperiod+change%}'}</li>
            <li onClick={copyToClipboard}>{'{appusagerevenue28d}'}</li>
            <li onClick={copyToClipboard}>{'{appusagerevenue28d+previousperiod+change%}'}</li>
            <li onClick={copyToClipboard}>{'{appusagerevenue7d}'}</li>
            <li onClick={copyToClipboard}>{'{appusagerevenue7d+previousperiod+change%}'}</li>
            <li onClick={copyToClipboard}>{'{appusagerevenue1d}'}</li>
            <li onClick={copyToClipboard}>{'{appusagerevenue1d+previousperiod+change%}'}</li>
        </ul>

        <ul class='list4'>
            <li onClick={copyToClipboard}>{'{apppayingstorescount365d}'}</li>
            <li onClick={copyToClipboard}>{'{apppayingstorescount365d+previousperiod+change%}'}</li>
            <li onClick={copyToClipboard}>{'{apppayingstorescount28d}'}</li>
            <li onClick={copyToClipboard}>{'{apppayingstorescount28d+previousperiod+change%}'}</li>
            <li onClick={copyToClipboard}>{'{apppayingstorescount7d}'}</li>
            <li onClick={copyToClipboard}>{'{apppayingstorescount7d+previousperiod+change%}'}</li>
            <li onClick={copyToClipboard}>{'{apppayingstorescount1d}'}</li>
            <li onClick={copyToClipboard}>{'{apppayingstorescount1d+previousperiod+change%}'}</li>
        </ul>

        <ul class='list5'>
            <li onClick={copyToClipboard}>{'{apparpu365d}'}</li>
            <li onClick={copyToClipboard}>{'{apparpu365d+previousperiod+change%}'}</li>
            <li onClick={copyToClipboard}>{'{apparpu28d}'}</li>
            <li onClick={copyToClipboard}>{'{apparpu28d+previousperiod+change%}'}</li>
            <li onClick={copyToClipboard}>{'{apparpu7d}'}</li>
            <li onClick={copyToClipboard}>{'{apparpu7d+previousperiod+change%}'}</li>
            <li onClick={copyToClipboard}>{'{apparpu1d}'}</li>
            <li onClick={copyToClipboard}>{'{apparpu1d+previousperiod+change%}'}</li>
        </ul>

    </div>
    </>)
}