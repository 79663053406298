import React, { useState, useEffect } from "react";
import axios from "utils/axios";
import { NavLink, useNavigate } from 'react-router-dom';
import { Button, Spin, Tooltip } from "antd";
import { useAuth } from "hooks/useAuth";
import { FieldTimeOutlined, CalendarOutlined } from '@ant-design/icons';
import { ToggleSwitch } from 'Components/toggleSwitch/ToggleSwitch';
import moment from 'moment';

const StatsBotContainer = (props) => {
    const navigate = useNavigate();

    const getGatewayName = (name) => {
        if (name === 'stripe')
            return "Stripe"
        else if (name == "shopify_partner")
            return "Shopify Partner"
    }

    const navigateToEdit = () => {
        const itemId = props.item.id;
        navigate(`/statsbot/${itemId}`);
    };

    const dateString = props.item.created;
    const formattedDate = moment(dateString).format("Do MMMM, hh:mm a");

    const time24hr = props.item.post_time;
    const time12hr = moment(time24hr, 'HH:mm:ss').format('h:mm A');

    var post_date_or_time = ''
    
    if (props.item.post_frequency === 'Weekly') post_date_or_time = `, ${props.item.post_day}`
    if (props.item.post_frequency === 'Monthly') post_date_or_time = `,  Day ${props.item.post_date}`

    return (
        <div class="statbot-container">
            <div class='statbot-header'>
                <h1 style={{ fontWeight: "500" }}>{props.item.name}</h1>
                <Button className="statbot-button" onClick={navigateToEdit}>
                    View or Modify
                </Button>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div class="statbot-div">
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.7em' }} >
                        <FieldTimeOutlined style={{ marginRight: '0.7em' }} />{props.item.post_frequency}{post_date_or_time}, {time12hr}, {props.item.timezone}</div>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.7em' }}>
                        <img alt="slack" src="/static/slack.png" className="statbot-div-img" />
                        Slack, {props.item.message_destination.name}, #{props.item.message_channel_id} channel
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.7em' }}>
                        {/* Show relevant datasource image */}
                        {props.item.data_source.gateway_name == 'stripe' && <img alt="stripe" src="/static/stripe.png" className="statbot-div-img" />}
                        {props.item.data_source.gateway_name == 'shopify_partner' && <img alt="shopify_partner" src="/static/shopify.png" className="statbot-div-img" />}

                        {getGatewayName(props.item.data_source.gateway_name)}, {props.item.data_source.name}
                    </div>
                    <div className="statbot-div-item">
                        <CalendarOutlined style={{ marginRight: '0.7em' }} /> Created on {formattedDate}
                    </div>
                </div>
                <ToggleSwitch statsbotId={props.item.id} isActive={props.item.is_active} />
            </div>
        </div>
    )
}

const StatsBot = () => {

    const [statbots, setStatsBots] = useState(null)
    const { isSubscribed, isEnterprise } = useAuth();
    const [isIntegrationSetup, setIsIntegrationSetup] = useState(true)
    const navigate = useNavigate()

    const fetchIntegrationData = async () => {
        try {
            // Fetch the number of dataSource accounts
            const dataSources = await axios.get("source/");
            const dataSourcesCount = dataSources.data.length;

            // Fetch the number of Slack accounts
            const slackRes = await axios.get("message/slack");
            const slackCount = slackRes.data.length;

            // Check the conditions and navigate accordingly
            if (slackCount === 0 || dataSourcesCount === 0) {
                setIsIntegrationSetup(false);
            }
            else {
                setIsIntegrationSetup(true);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        axios
            .get("bot/")
            .then((res) => setStatsBots(res.data))
            .catch(error => console.log(error))

        fetchIntegrationData();
    }, [])

    const isButtonDisabled = !isIntegrationSetup // Integration is not setup
        || (statbots && !isSubscribed && statbots.length >= 1) // Free Plan
        || (statbots && !isEnterprise && statbots.length >= 10) // In paid plan, not enterprise
        || (statbots && statbots.length >= 50); // If interprise, limit is 50

    const navigateToNew = () => {
        navigate('/statsbot/new');
    };

    return (
        <div class='statbot'>
            <div class='statbot-header'>
                <h2> StatsBot </h2>
                <Tooltip title={isButtonDisabled ?
                    (!isIntegrationSetup ? 'Connect at least 1 message destination & 1 data source to create a Stats Bot' : (
                        (statbots && !isSubscribed && statbots.length >= 1) ? 'Free plan includes 1 Stats Bot. Upgrade to create more.' : 'Plan limit reached.')
                    ): ''
                }>
                    <Button className="statbot-button" disabled={isButtonDisabled} onClick={navigateToNew}>
                        + Configure New Bot
                    </Button>
                </Tooltip>
            </div>
            {statbots ? (
                statbots.length > 0 ? (
                    statbots.map((item) => <StatsBotContainer item={item} />)
                ) :
                    <p>Create your first Stats Bot by clicking on the [+ Configure New Bot] button.
                        <br /> <br />
                        Note: For Stats Bot to work, at least 1 Message Destination and 1 Data Source must be connected in the <NavLink to="/integrations"><u>Integrations page</u></NavLink>.</p>
            ) : (
                <p><Spin size="small" /></p>
            )}

            {statbots && !isSubscribed && statbots.length >= 1 &&
                <p>
                    To connect more Stats Bots, <NavLink to="/billing"><u>upgrade to a paid plan</u></NavLink>.
                </p>
            }

        </div>
    )
}

export default StatsBot;