import { Form, Input, Button, message } from "antd";
import { Row, Col } from "antd";
import axios, { setTokenToAxios } from "utils/axios";
import { Navbar } from "Components/common";
import { useAuth } from "hooks/useAuth";

const tailLayout = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 18, offset: 3 },
    md: { span: 18, offset: 3 },
  },
};

const Login = () => {
  const { fetchCurrentUser } = useAuth();

  const onFinish = async (values) => {
    try {
      var res = await axios.post("/accounts/login", values);
      
      if (res && res.data.auth_token) {
        localStorage.setItem("RS_auth_token", res.data.auth_token);
        setTokenToAxios(res.data.auth_token);
        fetchCurrentUser();
      } else {
        message.error("Something went wrong while logging in.");
      }
    } catch (error) {
      
      message.error(
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.detail) ||
          "Something went wrong"
      );
    }
  };

  const onFinishFailed = (errorInfo) => {
    message.error("Something went wrong.");
  };

  return (
    <>
      <Navbar />
      <Row>
        <Col
          xs={{ span: 16, offset: 4 }}
          sm={{ span: 12, offset: 6 }}
          md={{ span: 6, offset: 9 }}
        >
          <h4 className="form-header">Login to your account</h4>
          <Form
            name="login"
            layout="vertical"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          > 
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please enter your email",
                },
                {
                  type: "email",
                  message: "Please enter a valid email address",
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please enter your password",
                },
              ]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button type="primary" block htmlType="submit">
                Login
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>

      <div style={{ textAlign: "center", marginTop: 24 }}>
        <p>
          Forgot your password? <a href="/resetpassword">Reset password</a>
        </p>
        <p>
          Need help?{" "}
          <a href="mailto:hello@statsbot.xyz?subject=StatsBot%3A%20Contact%20Support&body=Hi%2C%20I%20need%20help%20with%20StatsBot.">
            Contact support
          </a>
        </p>
      </div>
    </>
  );
};

export default Login;
