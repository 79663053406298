import React, { useState, createContext, useEffect, useCallback } from "react";
import axios from "utils/axios";

export const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [isLoading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("RS_auth_token");
    if (token) {
      fetchCurrentUser();
    } else {
      setLoading(false);
    }
    //return () => {};
  }, []);

  const fetchCurrentUser = useCallback(async () => {
    try {
      var res = await axios.get("/accounts/me");
      setCurrentUser(res.data);
    } catch (error) {
      console.log("Something went wrong!");
      setCurrentUser(null);
    } finally {
      setLoading(false);
    }
  }, []);

  const data = {
    currentUser,
    setCurrentUser,
    isLoading,
    fetchCurrentUser,
    isSubscribed:
      (currentUser?.subscription && currentUser?.subscription?.is_active) ||
      false,
    isEnterprise:
      (currentUser?.subscription && currentUser?.subscription?.is_enterprise) ||
      false,
  };

  return <AuthContext.Provider value={data}>{children}</AuthContext.Provider>;
}
