import { StatsBotForm } from "Components/statsBotForm/StatsBotForm";
import React, { useEffect, useState } from "react";
import axios from "utils/axios";
import { useParams, useNavigate } from 'react-router-dom';
import { Button, message, Popconfirm, Alert } from "antd";
import { ToggleSwitch } from "Components/toggleSwitch/ToggleSwitch";
import moment from "moment";

const ModifyStatsBot = () => {

    const navigate = useNavigate()
    const { id } = useParams();
    const [statsBot, setStatsBot] = useState(null)

    useEffect(() => {
        // Fetches data to free fill the form
        axios
            .get(`bot/${id}`)
            .then(res => setStatsBot(res.data))
            .catch(error => console.error(error));

    }, [])

    const handleUpdate = async (values) => {
        // update the stats bot
        try {
            var res = await axios.put(`bot/${id}`, values);
            message.success("StatsBot updated succesfully!")

            return res;
        } catch (error) {
            message.error(
                (error &&
                    error.response &&
                    error.response.data &&
                    error.response.data.detail) ||
                "Something went wrong"
            );
        }
    };

    const deleteStatsBot = async () => {

        try {
            var res = await axios.delete(`bot/${id}`);
            message.success("StatsBot deleted succesfully!")
            navigate("/statsbot")
        } catch (error) {
            message.error(
                (error &&
                    error.response &&
                    error.response.data &&
                    error.response.data.detail) ||
                "Something went wrong"
            );
        }
    }

    const dateString = statsBot && statsBot.created;
    const formattedDate = moment(dateString).format("Do MMMM, hh:mm a");

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div>
                    <h2>Modify Stats Bot</h2>
                    {statsBot && <div style={{ marginBottom: "2em", color: "#4F4F4F", fontSize: "12px" }}>Created on {formattedDate}</div>}
                </div>
                <div>
                    {statsBot && < ToggleSwitch isActive={statsBot.is_active} statsbotId={statsBot.id}/>}
                    <Popconfirm
                        title="Confirm"
                        description="Are you sure you want to delete this bot?"
                        okText="Yes, Delete"
                        onConfirm={deleteStatsBot}
                    >
                        <Button type='link' style={{ color: '#FF4D4F' }}>
                            Delete Bot
                        </Button>
                    </Popconfirm>
                </div>
            </div>

            {statsBot && !statsBot.data_source.id && <Alert style={{ margin: "1.5em 0" }}
                message="Error: Data Source removed"
                description="The data source configured in this Stats Bot has been removed. Add it again from the Integrations page, or choose another data source."
                type="error"
                closable
            />}

            {statsBot && !statsBot.message_destination.id && <Alert style={{ margin: "1.5em 0" }}
                message="Error: Message Destination removed"
                description="The message destination configured in this Stats Bot has been removed. Add it again from the Integrations page, or choose another data source."
                type="error"
                closable
            />}

            <StatsBotForm isEdit={true} initialValues={statsBot} onSubmit={handleUpdate} />
        </div>
    )
}

export default ModifyStatsBot;