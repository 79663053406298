import React from "react";
import { Navbar } from "Components/common";

const Error404 = () => {
  return (
    <>
      <Navbar />
      <div style={{ textAlign: "center" }}>
        <h2>404</h2>
      </div>
    </>
  );
};

export default Error404;
