import React from "react";
import { Spin } from "antd";
import { Route, Navigate } from "react-router-dom";
import { useAuth } from "hooks/useAuth";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isLoading, currentUser } = useAuth();

  if (isLoading) {
    return (
      <Spin style={{ position: "absolute", top: "50%", left: "50%" }} />
    )
  }

  if (currentUser) {
   return <Component {...rest} /> 
  }
  
  return <Navigate to="/login" />

};

export default PrivateRoute;
