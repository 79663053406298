import React, { useState } from "react";
import { Switch, message } from "antd";
import axios from "utils/axios";

const ToggleSwitch = ({ isActive, statsbotId }) => {

    const [toggleActive, setToggleActive] = useState(isActive);
    const [loading, setLoading] = useState(false);

    const handleSwitchToggle = (checked) => {

        const endpoint = checked ? `bot/activate/${statsbotId}` : `bot/deactivate/${statsbotId}`;

        setLoading(true);
        axios
            .post(endpoint)
            .then((res) => {
                setLoading(false);
                setToggleActive(checked)
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
                message.info(error.response.data.message)
            });
    };

    return (<Switch
        checkedChildren="Running"
        unCheckedChildren="Paused"
        onChange={handleSwitchToggle}
        defaultChecked={isActive}
        checked={toggleActive}
        loading={loading}
    />)
}

export { ToggleSwitch };